import axios from 'axios'
axios.defaults.baseURL = "http://www.weaiove.com";
export const apiGetHomeData = () => axios.get('/home.php/index')

export const apiGetDevelopment = () => axios.get('/home.php/course')

export const apiGetNews = () => axios.get('/home.php/newlist')

export const apiGetNewsDetails = (id) => axios.get('/home.php/category?id=' + id)

export const apiGetSearch = (content) => axios.get('home.php/newseach?seach=' + content)

export const apiGetCate = (content) => axios.get('home.php/newcate?year=' + content)

//////////////////////////////////////////

export const apiGetMediaLists = () => axios.get('/home.php/medialist')

export const apiGetSearchMedia = (content) => axios.get('home.php/mediaseach?seach=' + content)

export const apiGetCateMedia = (content) => axios.get('home.php/mediacate?year=' + content)

//////////////////////////////////////////

export const apiGetVideos = () => axios.get('home.php/videolist')

export const apiGetPaintings = () => axios.get('home.php/painting')

export const apiGetCities = () => axios.get('home.php/relation')

export const apiGetOffers = () => axios.get('home.php/coffer?type=2&seach=')

export const apiGetOfferDetails = (id) => axios.get('home.php/offerseach?id=' + id)

export const apiGetOfferSearch = (type, search) => axios.get('home.php/coffer?type=' + type + '&seach=' + search)

export const apiGetQrcode = () => axios.get('home.php/qrcode')

export const apiGetQrcodeById = (id) => axios.get('home.php/qrcode?area=' + id)

export const apiGetDevelop = () => axios.get('home.php/develop') 

export const apiGetArrlist = (params) => axios.get('home.php/arrlist?'+params) 

export const apiGetStafflist = () => axios.get('home.php/stafflist') 
